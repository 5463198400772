module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-canonical-urls@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.0__react-do_sfurmb4ep5rmea25snqkiobpz4/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://mgh.space","stripQueryString":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.0__react-dom@1_46eq2ar3dcodheteppi6gbskqa/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-LCG31D32D6"],"pluginConfig":{"head":false,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.0__react-dom@18.3_h2nguap352mcmyqekz77px4c7i/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/images/favicon-32x32.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"624d2a65b7df2727cfd6a80353aab166"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__hdbmt5kaxv2q5fdxmwappgagky/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
